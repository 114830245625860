import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components"
import { isMobile } from "react-device-detect";

import EmailLookupForm from "../forms/EmailLookupForm"
import TextField from '../fields/TextField';
import HoverButton from "../buttons/HoverButton"
import { postData } from "../../services/server"
import { colors } from "../../settings"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../state/UserContext"

const Form = styled.div`
    display: flex;
    flex: 0;

    > div {
        flex: ${isMobile ? 1:0};
        min-width: 225px;
    }

    > button {
        margin: 8px 2px 0px 2px;
    }
`

function CouponForm(props) {
    const userContext = useContext(UserContext);

    const [coupon, setCoupon] = useState("");
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");
    const [busy, setBusy] = useState(false);
    const [login, setLogin] = useState(false);

    useEffect(
        () => {
            setCoupon(props.cart ? props.cart.coupon_code || "" : "");

            if (props.cart.coupon_msg)
                setError(props.cart.coupon_msg);
        }, 
        [props.cart]
    );

    function onAction(e, action, options={}) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setBusy(true);
        setError("");
        setMsg("");

        postData("/api/cart/"+action+"coupon", {
            cart_id: props.cart.id,
            current_user_id: options && options.user_id ? options.user_id : userContext.isAuthenticated() ? userContext.profile.id : 0,
            coupon
        },
        function(result) {
            setMsg(action=="remove" ? "Purchase Code has been removed.":result.coupon_title);//"Coupon has been applied.")
            props.onCartChange(result);
        },
        function(error, status, response) {
            console.log("response", response)
            if (status == 404)
                setError("Purchase Code not found.")
            else if (status == 402)
                setLogin(true);
            else if (status == 401)
                setError("Purchase Code is no longer valid.")
            else if (status == 501)
                setError("Product not found in cart.")
            else 
                setError(error);
        },
        function() {
            setBusy(false);
        }
      );
    }
    function onApply(e, options={}) {
        setLogin(false);
        onAction(e, 'apply', options)
    }
    function onRemove(e) {
        onAction(e, 'remove')
    }

    return (
        <>
            <Form>            
                <TextField
                    name="purchasecode"
                    label={props.label || "Purchase Code"}
                    value={coupon}
                    small
                    onChange={(e) => { setCoupon(e.target.value) }}
                    onKeyDown={(e) => {
                        if (e.key == "Enter")
                            onApply(e);
                    }}
                    disable={busy}
                    variant="outlined"
                >
                    <HoverButton 
                        color="blue"
                        label="Apply"
                        size="small"
                        busy={busy}
                        disabled={busy || coupon.length === 0}
                        onClick={onApply}
                    />
                    {(props.cart && props.cart.coupon_id > 0) &&
                        <HoverButton 
                            color="red"
                            icon={faTimes}
                            size="small"
                            busy={busy}
                            disabled={busy}
                            onClick={onRemove}
                        />
                    }
                </TextField>
            </Form>
            {(msg && msg.length > 0) && 
                <div style={{color: colors.green, marginTop: "10px"}}>
                    <FontAwesomeSvgIcon icon={faCheck} />
                    &nbsp;
                    {msg}
                </div>
            }
            {(error && error.length > 0) && 
                <div style={{color: "red", marginTop: "10px"}}>
                    {error}
                </div>
            }
            {login && 
                <div>
                    <p>
                        Please login to use this purchase code.
                    </p>
                    <EmailLookupForm
                        password={false}
                        nopadding
                        onResult={(data) => {
                            onApply(null, {user_id: data.user.id});
                        }}
                    />   
                </div>         
            }
            {(!login && props.cart.coupon_activation_info) &&
                <div>
                    <div><small style={{color: "red"}}>{props.cart.coupon_type}</small></div>
                    <div><small style={{color: "red"}}>{props.cart.coupon_activation_info}</small></div>
                </div>
            }

            {props.cart.admin_coupon_code && 
                <div style={{color: "green", marginTop: "10px"}}>
                    {props.cart.admin_coupon_code} has also been applied
                </div>
            }
        </>
    )
}

export default CouponForm