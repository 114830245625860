import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faCheck, faTruck, faTruckMoving } from "@fortawesome/free-solid-svg-icons";

import Dialog from '../layout/Dialog'
import TextField from '../fields/TextField';
import SelectField from '../fields/SelectField';

import CheckoutButtons from "./Buttons";
import Button from "../buttons/Button"
import CouponForm from "../forms/CouponForm"
import Layout3Columns from "../layout/Layout3Columns"

import { UserContext} from "../../state/UserContext"
import { CartContext } from '../../state/CartContext';
import { removeNulls, formatPrice } from "../../utilities/utils"
import { devices } from "../../settings"

const DataForm = styled.form`
    > div {
        margin-bottom: 12px;

        &.medium {
          width: 50%;

          @media ${devices.mobile} {
            width: 65%;
          } 
        }
        &.small {
          .MuiFormControl-root {
            width: 25%;

            @media ${devices.mobile} {
              width: 35%;
            }
          }
        }
    }
`
const FreightWarning = styled.div`
    margin: 20px 0px;
    font-weight: 300;

    svg {
      margin-right: 5px;
    }
`
const RushOptions = styled.div`
    display: flex;

    > div {
      flex: 1;

      > div {
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media ${devices.mobile} {
            display: block;
          } 
        }
      }
    }
`
const Surcharge = styled.div`
    display: flex;
    align-items: center;

    strong {
      color: red;
      font-weight: normal;
    }

    @media ${devices.mobile} {
      display: block;
      margin: 10px 0px;
    } 
`
const OutOfArea = styled.div`
  display: flex;
  margin-top: 20px;

  @media ${devices.mobile} {
    display: block;
    margin-top: 10px;

    button {
      margin-bottom: 5px;
      width: 100%;
    }
  } 
`

const ShippingData = (props) => {  
    const userContext = useContext(UserContext);
    const cartContext = useContext(CartContext);

    const [error, setError] = useState("");
    const [busy, setBusy] = useState("");
    const [data, setData] = useState({
      ship_company: props.cart.ship_company || "", 
      ship_name: props.cart.ship_name || (userContext.isAuthenticated() ? userContext.profile.first_name + ' ' + userContext.profile.last_name : ''), 
      ship_address1: props.cart.ship_address1 || "", 
      ship_address2: props.cart.ship_address2 || "", 
      ship_city: props.cart.ship_city || "", 
      ship_state: props.cart.ship_state || "", 
      ship_zipcode: props.cart.ship_zipcode || ""
    })
    const [rush, setRush] = useState(false);
    const [errors, setErrors] = useState({
      ship_company: null,
      ship_name: null,
      ship_address1: null,
      ship_address2: null,
      ship_city: null,
      ship_state: null,
      ship_zipcode: null,
    })
    const [adding, setAdding] = useState(null);
    const [surcharge, setSurcharge] = useState(null);
    const [outOfArea, setOutOfArea] = useState(false);
    const [outOfAreaRequested, setOutOfAreaRequested] = useState(false);
    const staticData = useStaticQuery(graphql`
      query {
        accessories: allMysqlAccesories(filter: {title: {eq: "Rush Fee"}}) {
          edges {
              accessory: node {
                  accessory_id
                  title
                  description
              }
          }
        }
        sizes: allMysqlAccesorySizes {
          edges {
            size: node {
              id
              size_id
              accessory_id
              size
              cost
            }
          }
        }
        states: allMysqlStates(sort: {fields: abbrev2, order: ASC}) {
          edges {
              state: node {
                  id
                  abbrev2
                  state
                  outofarea_ind
                  surcharge: ship_freight_surcharge
              }
          }
        }
      }`
    )

    useEffect(
      () => {
          for (const item of props.cart.items) {
            if (item.title.indexOf("Rush Fee") != -1) {
              setRush(true);
            }
          }    
      }, 
      []
    );

    useEffect(
      () => {
          const stateData = staticData.states.edges.find(s => s.state.abbrev2 == data.ship_state);
          const state = stateData ? stateData.state : null;
        
          if (state && state.surcharge && state.surcharge > 0 && props.cart.shipping_method === "freight" && props.cart.shipping_method_id == 2)
            setSurcharge(state.surcharge)
          else 
            setSurcharge(null);

          if (state)
            setOutOfArea(state.outofarea_ind == 1)
          else 
            setOutOfArea(false);            
      }, 
      [data]
    );

    function onFieldChange(e) {
        setData({...data, [e.target.name]: e.target.value});
    }

    function validateForm() {
        const address1Valid = data.ship_address1 && data.ship_address1.length > 0;
        const cityValid = data.ship_city && data.ship_city.length > 0;
        const stateValid = data.ship_state && data.ship_state.length > 0;
        const zipValid = data.ship_zipcode && data.ship_zipcode.length > 0;

        setErrors({
          ship_address1: !address1Valid,
          ship_city: !cityValid,
          ship_state: !stateValid,
          ship_zipcode: !zipValid,
        });

        return address1Valid && cityValid && stateValid && zipValid;
    }

    function onNext() {
        const valid = validateForm();

        if (valid) {
            setBusy("cart");

            cartContext.updateCartShipping(removeNulls(data), (cart) => {
              if (cart) {
                props.onCartChange(cart)
                props.onContinue(); 
              }
              else 
                props.onError("Error updating shipping data.");
      
                setBusy("");
            })
        }
    }

    function addAccessory(accessorySize) {
      setAdding(accessorySize.id);
  
      cartContext.addAccessoryToCart(accessorySize.size_id, 1, null, (cart) => {
        if (cart) {
          props.onCartChange(cart)
          props.onCart()
        }
        else {
          setError("An error occured adding the rush fee to your cart.")
          setAdding(null);
        }
      })
    }
    
    function onGetShipPrice() {
      setBusy("outofarea")

      cartContext.requestShippingPrice(removeNulls(data), (cart) => {
        if (cart) {
          props.onCartChange(cart)
          setOutOfAreaRequested(true);
        }
        else {
          setError("An error occured requesting shipping price.")
        }

        setBusy("");
      })
    }

    return (
      <>
        <div>
            {props.cart.shipping_method === "freight" && 
              <FreightWarning>
                <FontAwesomeSvgIcon icon={faTruck} />
                This order will ship <strong>freight</strong>.  It must be shipped to a 
                commercial address.
              </FreightWarning>
            }
            <DataForm>
                <div className="medium">
                    <TextField 
                        value={data.ship_company}
                        wide
                        error={errors.ship_company === true}
                        variant="outlined"
                        id="ship_company"
                        name="ship_company"
                        label="Company"
                        onChange={onFieldChange} 
                    />
                </div>
                <div className="medium">
                    <TextField 
                        value={data.ship_name}
                        wide
                        error={errors.ship_name === true}
                        variant="outlined"
                        id="ship_name"
                        name="ship_name"
                        label="Care Of"
                        onChange={onFieldChange} 
                    />
                </div>
                <div>
                    <TextField 
                        value={data.ship_address1}
                        wide
                        error={errors.ship_address1 === true}
                        variant="outlined"
                        id="ship_address1"
                        name="ship_address1"
                        label="Shipping Address 1"
                        onChange={onFieldChange} 
                        required 
                        autoComplete="address-line1"
                    />
                </div>
                <div>
                  <TextField 
                        value={data.ship_address2}
                        wide
                        error={errors.ship_address2 === true}
                        variant="outlined"
                        id="ship_address2"
                        name="ship_address2"
                        label="Shipping Address 2"
                        autoComplete="address-line2"
                        onChange={onFieldChange} 
                    />
                </div>
                <Layout3Columns>
                  <div>
                    <TextField 
                          value={data.ship_city}
                          wide
                          error={errors.ship_city === true}
                          variant="outlined"
                          id="ship_city"
                          name="ship_city"
                          label="City"
                          onChange={onFieldChange} 
                          required 
                          autoComplete="shipping address-level2"
                      />
                  </div>
                  <div>
                    <SelectField 
                          value={data.ship_state}
                          wide
                          error={errors.ship_state === true}
                          variant="outlined"
                          id="ship_state"
                          name="ship_state"
                          label="State"
                          options={staticData.states.edges.map(({state}) => {
                            return {
                              value: state.abbrev2,
                              label: state.abbrev2
                            }
                          })}
                          onChange={onFieldChange} 
                          required 
                          autoComplete="shipping address-level1"
                    />
                  </div>
                  <div>
                    <TextField 
                        value={data.ship_zipcode}
                        wide
                        error={errors.ship_zipcode === true}
                        variant="outlined"
                        id="ship_zipcode"
                        name="ship_zipcode"
                        label="Zip Code"
                        onChange={onFieldChange} 
                        required 
                        autoComplete="shipping postal-code"
                    />
                  </div>
                </Layout3Columns> 

                <div>
                  {surcharge && 
                    <Surcharge>
                      Due additional fees when shipping to this state, we must charge an additional ${surcharge} surcharge
                    </Surcharge>
                  }
                  {(outOfArea && props.cart.coupon_type != "Out Of Area Shipping") &&
                      <Surcharge>
                        <div>
                          <strong>This is out of our standard shipping area.</strong>
                          Please request shipping price below or give us a call at (800) 863-0107 to arrange shipping.
                        </div>
                      </Surcharge>
                  }
                  {(outOfArea && props.cart.coupon_type == "Out Of Area Shipping") &&
                      <Surcharge>
                        <div>
                          Out of standard area shipping surcharge is <strong>{formatPrice(props.cart.coupon_amount)}</strong>
                        </div>
                      </Surcharge>
                  }                  
                </div>

            </DataForm>
        </div>

        {(outOfArea && props.cart.coupon_type != "Out Of Area Shipping") ? 
          <OutOfArea>
            <Button 
              color="orange"
              label="Get Shipping Price"
              icon={faTruckMoving}
              size="large"
              onClick={onGetShipPrice}
              busy={busy == "outofarea"}
              style={{marginRight:"15px"}}
            />
           
            <CouponForm 
              cart={props.cart}
              label="Shipping Code"
              size="large"
              onCartChange={(updatedCart) => {
                cartContext.saveCart(updatedCart);
                props.onCartChange(updatedCart);
                props.onContinue();
              }}
            />
          </OutOfArea>
        :
          <CheckoutButtons
            busy={busy == "cart"}
            onBack={props.onBack}
            onNext={onNext}
          />        
        }

        {outOfAreaRequested && 
          <Dialog
            icon={faCheck}
            title="Shipping Price Requested"
            actions={[{
              label: "Close",
              color: "blue",
              onClick: () => setOutOfAreaRequested(false)
            }]}
            onClose={() => setOutOfAreaRequested(false)}
          >
            <p>
                We have received your request for an out of area shipping price.  We 
                will contact your shortly with a <strong>shipping price</strong> and <strong>Shipping Code</strong> to
                complete your order. 
            </p>
          </Dialog>        
        }
      </>
    )
}

export default ShippingData

/*
          {(false && !rush) && 
            <Grid item  xs={12} sm={5}>
              <ProductBox>
                <h2>Rush Shipping</h2>
                <p>
                  SignMonkey.com ships all our signs within <strong>five</strong> working days. If you
                  need your sign sooner then you can add Rush Fabrication below:
                </p>
                {false && <p>
                  Signs Ordered now will ship on {' '} <strong><ShipDate /></strong>.
                </p>}
                <RushOptions>
                  <GoogleImage 
                    src="rush3day.jpg" 
                    required 
                    lazy                
                    alt="Rush Shipping" 
                    style={{
                      display:"block",
                      width:"170px",
                      height:"150px",
                      margin:"5px"
                    }} />
                  <div>
                    {staticData.accessories.edges.map(({accessory}, index) => (
                        <div key={index}>
                          <h4>{accessory.title}</h4>
                          {staticData.sizes.edges.map(({size}) => (
                              size.accessory_id == accessory.accessory_id && 
                              <div key={size.id}>
                                <span dangerouslySetInnerHTML={{__html: size.size}} />
                                &nbsp;                      
                                ${size.cost}
                                &nbsp;
                                <HoverButton 
                                  label="Add To Cart"
                                  color="green"
                                  busy={adding == size.id}
                                  disabled={adding == size.id}
                                  onClick={() => addAccessory(size)}
                                />  
                              </div>
                          ))}
                        </div>
                    ))}
                  </div>
                </RushOptions>
              </ProductBox>
            </Grid>
          }
*/