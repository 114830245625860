import React from "react"
import styled from "styled-components"

import { devices } from "../../settings"

const Layout = styled.div`
  display: flex;
  align-items: ${props => props.align || "center"};

  > div {
      &:first-child {
        flex-basis: ${props => props.left || 33.3}%;
        padding-right: ${props => props.padding || 5}px;
      }
      &:nth-child(2) {
        flex-basis: ${props => props.middle || 33.3}%;
        padding-right: ${props => props.padding || 5}px;
      }
      &:last-child {
        flex-basis: ${props => props.right || 33.3}%;
        padding-left: ${props => props.padding || 5}px;
      }
  }

  @media ${devices.mobile} {
      display: block;
  }
`

export default (props) => {
  return (
    <Layout align={props.align} padding={props.padding} left={props.left} right={props.right}>
        {props.children}
    </Layout>
  )
}