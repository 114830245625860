import React, { useState, useContext } from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { isMobile } from "react-device-detect";

import CheckoutButtons from "./Buttons"
import CouponForm from "../forms/CouponForm"
import CartItems from "./CartItems"

import { CartContext } from '../../state/CartContext';
import { formatPrice } from "../../utilities/utils"
import { colors, devices } from "../../settings"

const Totals = styled.div`
  
  .total {
    font-size: 20px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 0px;
    }

    span {
      text-align: right;
    }
    label {
      min-width: 150px;
      margin-right: 10px;
      white-space: nowrap;
    }

    .total {
      color: ${colors.green};
      border-top: 1px solid #ccc;

      label {
        color: black;
      }
      span {
        font-weight: bold;
      }
    }
    .discount {
      color: black;
    }
    .shipping {
      color: black;
    }
    text-align: right;
  }

  justify-content: space-between;
  padding: 25px 0px 5px 0px;
  margin-bottom: 0px;
  display: flex;

  @media ${devices.mobile} {
    flex-direction: column-reverse;

    > div:nth-child(1) {
      margin-top: 20px;
    }
    > div:nth-child(2) {
      border-top: 1px solid #ccc;
    }
  } 
`
const Footer = styled.div`
  display:flex;
  justify-content: space-between;

  @media ${devices.mobile} {
    flex-wrap: wrap-reverse;
  } 

  > div:nth-child(2) {
    margin-top: 30px;
  }
`

const CartReview = (props) => {  
    const cartContext = useContext(CartContext);
    const [updating, setUpdating ] = useState(null);

    const cart = props.cart;
    const haveCoupon = cart.coupon_code || cart.admin_coupon_code;
    const partialPayment = haveCoupon && (cart.coupon_type == "Partial Payment" || cart.admin_coupon_type == "Partial Payment");

    return (
      <>
        <CartItems 
          items={cart.items}
          quantity={true}
          actions={true}
          total={true}
          updating={updating}
          onQuantityChange={(item, value) => {
            setUpdating(item);

            cartContext.updateCartQuantity(item, value, (cart) => {
              if (cart)
                props.onCartChange(cart)
              else 
                props.onError("Error updating quantity.");
      
              setUpdating(null);
            })
          }}
          onRemoveItem={(item) => {
            setUpdating(item);
            cartContext.removeFromCart(item, (cart) => {
              if (cart)
                props.onCartChange(cart)
              else 
                props.onError("Error removing item from cart.")
                setUpdating(null);
            })
          }}
        />

        <Totals>
            <div>
              * Order additional copies of a sign for a <strong>15% discount</strong>.
            </div>
            <div>
              <div className="total">
                {(updating) ?
                  <FontAwesomeSvgIcon icon={faSpinner} spin />
                :
                  <>
                    {cart.shipping_surcharge > 0 ?
                        <div>
                          <label>Shipping Surcharge:</label>
                          <span>{formatPrice(cart.shipping_surcharge)}</span>
                        </div>
                        :
                        <div className="shipping">
                          <label>Shipping:</label>
                          <span>FREE</span>
                        </div>
                    }
                    {(haveCoupon && cart.coupon_type != "Partial Payment" && cart.coupon_type != "Out Of Area Shipping") && 
                      <div className="discount">
                        <label>Purchase Code:</label>
                        <span>-{formatPrice(cart.coupon_discount)}</span>
                      </div>
                    }
                    {(cart.tax && cart.tax > 0) && 
                      <div className="tax">
                        <label>State Tax:</label>
                        <span>{formatPrice(cart.tax)}</span>
                      </div>
                    }
                    <div className={partialPayment?"":"total"}>
                      <label>Order Total:</label> 
                      <span>{formatPrice(cart.total)}</span>
                    </div>
                    {partialPayment && 
                      <div className="discount">
                        <label>Deferred Payment:</label>
                        <span>{formatPrice(cart.coupon_type == "Partial Payment" ? cart.coupon_amount : cart.admin_coupon_amount)}</span>
                      </div>
                    }
                    {partialPayment && 
                      <div className="total">
                        <label>Balance Due:</label>
                        <span>{formatPrice(cart.total - parseFloat(cart.coupon_type == "Partial Payment" ? cart.coupon_amount : cart.admin_coupon_amount))}</span>
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </Totals>
          <Footer>
            <CheckoutButtons
                  onNext={props.onContinue}
                  onContinueShop={props.continueToShop ? props.onContinueShop : null}
                />
            <div style={{maxWidth:"350px"}}>
              <CouponForm 
                cart={cart}
                onCartChange={(updatedCart) => {
                  cartContext.saveCart(updatedCart);
                  props.onCartChange(updatedCart)
                }}
              />
            </div>
          </Footer>
      </>
    )
}

export default CartReview