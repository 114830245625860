import React, { useState, useContext } from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect";

import TextField from "../fields/TextField";
import CheckoutButtons from "./Buttons"
import { UserContext} from "../../state/UserContext"
import { CartContext } from '../../state/CartContext';
import { isEmailAddress, isValidPhoneNumber } from "../../utilities/validation"
import { formatPhoneNumber, parsePhoneNumber } from "../../utilities/utils"

const DataForm = styled.form`
    display: ${isMobile ? "block":"flex"};
    
    > div {
        flex: 1;
        padding: ${isMobile ? 0:5}px;
        margin-bottom: ${isMobile ? 15:0}px;
    }
    > div:first-child {
        padding-left: 0px;
    }
    > div:last-child {
        padding-right: 0px;
    }

`

const ContactData = (props) => {  
    const userContext = useContext(UserContext);
    const cartContext = useContext(CartContext);
    const user = userContext.isAuthenticated() ? userContext.profile : null;

    const [busy, setBusy] = useState(false);
    const [data, setData] = useState({
        name: props.cart.user_name || (user ? user.name : ""),
        email: props.cart.user_email || (user ? user.email : ""),
        phoneno: props.cart.user_phoneno || (user ? user.phoneno : "")
    })
    const [errors, setErrors] = useState({
        name: null,
        email: null,
        phoneno: null
    })

    function onFieldChange(e) {
        setErrors({
            name: false,
            email: false,
            phoneno: false
        });

        let name = e.target.name;
        let value = e.target.value;

        if (name == "phoneno")
            value = parsePhoneNumber(value);

        setData({
            ...data, 
            [name]: value
        });
    }

    function validateForm() {
        const nameValid = data.name && data.name.length > 0;
        const emailValid = data.email && data.email.length > 0 && isEmailAddress(data.email);
        const phonenoValid = data.phoneno && data.phoneno.length > 0 && isValidPhoneNumber(data.phoneno);

        setErrors({
            name: !nameValid,
            email: !emailValid,
            phoneno: !phonenoValid
        });

        return nameValid && emailValid && phonenoValid;
    }

    function onNext() {
        const valid = validateForm();

        if (valid) {
            setBusy(true);
            
            cartContext.updateCartContact(data, (cart) => {
                if (cart) {
                  props.onCartChange(cart)
                  props.onContinue(); 
                }
                else 
                  props.onError("Error updating contact data.");
        
                  setBusy(false);
            })
        }
    }

    return (
      <>
        <DataForm>
            <div>
                <TextField 
                    value={data.name}
                    wide
                    error={errors.name === true}
                    variant="outlined"
                    id="contact_name"
                    name="name"
                    label="Name"
                    onChange={onFieldChange} 
                    required 
                    autoComplete="name"
                />
            </div>
            <div>
                <TextField 
                    value={formatPhoneNumber(data.phoneno)}
                    wide
                    error={errors.phoneno === true}
                    variant="outlined"
                    id="contact_phoneno"
                    name="phoneno"
                    label="Phone Number"
                    onChange={onFieldChange} 
                    required 
                    autoComplete="tel"
                />
            </div>
            <div>
                <TextField 
                    value={data.email}
                    wide
                    error={errors.email === true}
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email Address"
                    onChange={onFieldChange} 
                    required 
                    autoComplete="email"
                />
            </div>
        </DataForm>

        {(errors.name || errors.phoneno || errors.email) && 
            <div style={{color:"red"}}>
                Please correct the highlighted problems.
            </div>
        }

        <CheckoutButtons
          busy={busy}
          onBack={props.onBack}
          onNext={onNext}
        />
      </>
    )
}

export default ContactData