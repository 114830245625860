import React from "react"

export function useCachedState(storageKey, defValue) {
  const [value, setValue] = React.useState(
    typeof window !== `undefined` && storageKey in window.sessionStorage ? JSON.parse(window.sessionStorage.getItem(storageKey)) : defValue
  );

  React.useEffect(() => {
    // trap quota exceeded error
    try {
      window.sessionStorage.setItem(storageKey, JSON.stringify(value));
    }
    catch (err) {
      // silent
    }
  }, [storageKey, value]);

  return [value, setValue];
};