import React, { useContext } from "react"
import styled from "styled-components"
import queryString from 'query-string'

import EmailLookupForm from "../forms/EmailLookupForm"
import { CartContext } from '../../state/CartContext';

const Empty = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 30px;
  color: #999;
  font-weight: 300;
`
const Lookup = styled.div`
    max-width: 500px;
    margin: 35px auto;
`

const CartEmpty = (props) => {  
    const cartContext = useContext(CartContext);
    const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");

    return (
      <>
            <Empty>
              Your Shopping Cart Is Empty
            </Empty>

            <Lookup>
                <p>
                    Lookup your previously saved shopping cart.
                </p>
                <EmailLookupForm   
                    email={urlParams.email || ""}
                    password={false}           
                    onResult={(data) => {
                        if (data && data.cart) {
                            cartContext.saveCart(data.cart); 
                            props.onError("");
                            props.onCartChange(data.cart);
                        }
                        else {
                            props.onError("No data found.  Please verify you entered the email address correctly.")
                        }
                    }}
                />
            </Lookup>
      </>
    )
}

export default CartEmpty