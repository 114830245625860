import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect";
import { useStaticQuery, graphql } from "gatsby"

import Layout3Columns from "../layout/Layout3Columns"
import TextField from '../fields/TextField';
import SelectField from '../fields/SelectField';
import CheckField from '../fields/CheckField';

import CheckoutButtons from "./Buttons"
import { UserContext} from "../../state/UserContext"

import { CartContext } from '../../state/CartContext';
import { useCachedState } from "../../hooks/useCachedState"
import { postData } from "../../services/server"
import { removeNulls } from "../../utilities/utils"

const DataForm = styled.form`
    margin-top: 10px;
    
    > div {
        margin-bottom: 12px;

        &.medium {
          width: ${isMobile ? 65:50}%;
        }
        &.small {
          width: ${isMobile ? 35:25}%;
        }
    }
`

const BillingData = (props) => {  
    const cart = useContext(CartContext);
    const userContext = useContext(UserContext);

    const [sameAsShipping, setSameAsShipping] = useState(false);
    const [busy, setBusy] = useState(false);
    const [data, setData] = useState({
      billing_company: props.cart.billing_company || "", 
      billing_name: props.cart.billing_name || (userContext.isAuthenticated() ? userContext.profile.first_name + ' ' + userContext.profile.last_name : ''), 
      billing_address1: props.cart.billing_address1 || "", 
      billing_address2: props.cart.billing_address2 || "", 
      billing_city: props.cart.billing_city || "", 
      billing_state: props.cart.billing_state || "", 
      billing_zipcode: props.cart.billing_zipcode || ""
    })
    const [errors, setErrors] = useState({
      billing_company: null,
      billing_name: null,
      billing_address1: null,
      billing_address2: null,
      billing_city: null,
      billing_state: null,
      billing_zipcode: null,
    })
    //const [states, setStates] = useCachedState("states", []);
    const staticData = useStaticQuery(graphql`
      query {
        states: allMysqlStates {
          edges {
              state: node {
                  id
                  abbrev2
                  state
                  outofarea_ind
                  surcharge: ship_freight_surcharge
              }
          }
        }
      }`
    )

    // useEffect(
    //   () => {
    //       if (!states || states.length === 0) {
    //         postData("/api/cart/states", {},
    //           function(result) {
    //               setStates(result);
    //           },
    //           function(error) {
    //               console.log(error);
    //           }
    //         );
    //       }
    //   }, 
    //   []
    // );

    function onFieldChange(e) {
        setData({...data, [e.target.name]: e.target.value});
    }

    function validateForm() {
        const nameValid = data.billing_name && data.billing_name.length > 0;
        const address1Valid = data.billing_address1 && data.billing_address1.length > 0;
        const cityValid = data.billing_city && data.billing_city.length > 0;
        const stateValid = data.billing_state && data.billing_state.length > 0;
        const zipValid = data.billing_zipcode && data.billing_zipcode.length > 0;

        setErrors({
          billing_name: !nameValid,
          billing_address1: !address1Valid,
          billing_city: !cityValid,
          billing_state: !stateValid,
          billing_zipcode: !zipValid,
        });

        return nameValid && address1Valid && cityValid && stateValid && zipValid;
    }

    function onNext() {
        const valid = validateForm();

        if (valid) {
            setBusy(true);

            cart.updateCartBilling(removeNulls(data), (cart) => {
              if (cart) {
                props.onCartChange(cart)
                props.onContinue(); 
              }
              else 
                props.onError("Error updating billing data.");
      
                setBusy(false);
            })
        }
    }

    function onSameAsShipping(event) {   
      const same = event.target.checked;

      console.log(event.target.checked)
      setSameAsShipping(same);

      setData({
        billing_company: same ? props.cart.ship_company : "", 
        billing_name: same ? props.cart.ship_name : "", 
        billing_address1: same ? props.cart.ship_address1 : "", 
        billing_address2: same ? props.cart.ship_address2 : "", 
        billing_city: same ? props.cart.ship_city : "", 
        billing_state: same ? props.cart.ship_state : "", 
        billing_zipcode: same ? props.cart.ship_zipcode : ""
      })
      setErrors({
        billing_name: null,
        billing_address1: null,
        billing_city: null,
        billing_state: null,
        billing_zipcode: null,
      });
    };

    return (
      <>
        <CheckField 
          name="same_shipping"
          label=""
          simple
          options={[
            {value: "same", label: "Same As Shipping Address"}
          ]}
          value={sameAsShipping ? "same":""}
          onChange={onSameAsShipping}
        />
        
        <DataForm>
            <div className="medium">
                <TextField 
                    value={data.billing_company}
                    wide
                    error={errors.billing_company === true}
                    variant="outlined"
                    id="billing_company"
                    name="billing_company"
                    label="Company"
                    onChange={onFieldChange} 
                />
            </div>
            <div className="medium">
                <TextField 
                    value={data.billing_name}
                    wide
                    error={errors.billing_name === true}
                    variant="outlined"
                    id="billing_name"
                    name="billing_name"
                    label="Name"
                    onChange={onFieldChange} 
                    required
                />
            </div>
            <div>
                <TextField 
                    value={data.billing_address1}
                    wide
                    error={errors.billing_address1 === true}
                    variant="outlined"
                    id="billing_address1"
                    name="billing_address1"
                    label="Address 1"
                    onChange={onFieldChange} 
                    required 
                    autoComplete="shipping street-address"
                />
            </div>
            <div>
              <TextField 
                    value={data.billing_address2}
                    wide
                    error={errors.billing_address2 === true}
                    variant="outlined"
                    id="billing_address2"
                    name="billing_address2"
                    label="Address 2"
                    onChange={onFieldChange} 
                />
            </div>
            <Layout3Columns>
              <div>
                <TextField 
                      value={data.billing_city}
                      wide
                      error={errors.billing_city === true}
                      variant="outlined"
                      id="billing_city"
                      name="billing_city"
                      label="City"
                      onChange={onFieldChange} 
                      required 
                      autoComplete="shipping locality"
                  />
              </div>
              <div>
                <SelectField 
                      value={data.billing_state}
                      wide
                      error={errors.billing_state === true}
                      variant="outlined"
                      id="billing_state"
                      name="billing_state"
                      label="State"
                      // options={states.map(state => {
                      //   return {
                      //     value: state.abbrev,
                      //     label: state.abbrev
                      //   }
                      // })}  
                      options={staticData.states.edges.map(({state}) => {
                        return {
                          value: state.abbrev2,
                          label: state.abbrev2
                        }
                      })}                                        
                      onChange={onFieldChange} 
                      required 
                      autoComplete="shipping region"
                />
              </div>
              <div>
                <TextField 
                      value={data.billing_zipcode}
                      wide
                      error={errors.billing_zipcode === true}
                      variant="outlined"
                      id="billing_zipcode"
                      name="billing_zipcode"
                      label="Zip Code"
                      onChange={onFieldChange} 
                      required 
                      autoComplete="shipping postal-code"
                  />
              </div>
            </Layout3Columns>
        </DataForm>

        <CheckoutButtons
          busy={busy}
          onBack={props.onBack}
          onNext={onNext}
        />
      </>
    )
}

export default BillingData