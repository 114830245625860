import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { isMobile } from "react-device-detect";
import queryString from 'query-string'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faShoppingCart, faUser, faTruck, faMoneyBill, faCreditCard } from "@fortawesome/free-solid-svg-icons";

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import Loading from "../components/Loading"
import MajorError from "../components/MajorError"

import CartEmpty from "../components/checkout/CartEmpty"
import CartReview from "../components/checkout/CartReview"
import ContactData from "../components/checkout/ContactData"
import ShippingData from "../components/checkout/ShippingData"
import BillingData from "../components/checkout/BillingData"
import PaymentData from "../components/checkout/PaymentData"

import { postData } from "../services/server"
import { sendConversion } from "../services/google";
import { colors } from "../settings"
import { CartContext } from '../state/CartContext';
import { UserContext} from "../state/UserContext"
import { formatPrice, removeNulls } from "../utilities/utils"

const Prompt = styled.h3`
    font-size: ${isMobile ? 20:30}px;
    color: #666;
    font-weight: 300;
    margin: ${isMobile?20:30}px 0px;
`

const Steps = styled.div`
`
const Step = styled.div`
`
const StepHeader = styled.div`
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 90%;

    &:hover {
        border-color: ${colors.green};
    }
    &.active {
        border-color: ${colors.green};
        color: ${colors.green};
        padding: 10px;
        font-size: 100%;
    }

    > div {
        text-align: right;
        display: flex;
        align-items: center;

        button {
            margin: 10px 20px 10px 30px;
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 6px 15px;
        }
    }

    h3 {
        margin: ${isMobile ? 5:10}px;
        font-size: ${isMobile ? 18:25}px;
        font-weight: 200;
    }
    h4 {
        margin: 5px 0px;
        font-size: ${isMobile ? 15:22}px;
        font-weight: 200;
    }

    .cost {
        color: ${colors.green};
    }
    .balance {
        span {
            color: ${colors.red};
        }
    }
`

const CheckoutPage = (props) => {
    const userContext = useContext(UserContext);
    const cartContext = useContext(CartContext);

    const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");

    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState("cart");
    const [completed, setCompleted] = useState({});
    const [error, setError] = useState("");
  
    const steps = [
        {name:"cart", title:isMobile ? "Cart":"Review Cart"},
        {name:"contact", title:"Contact"},
        {name:"shipping", title:"Shipping"},
        {name:"billing", title:"Billing"},
        {name:"payment", title:"Payment"},
    ];

    function setHashStep() {
        const hash = window.location.hash || "";

        if (hash.length > 2) {
            const stepName = hash.substr(1);

            if (steps.find(x => x.name == stepName))
                setStep(stepName);
        }
    }

    useEffect(
      () => {          
          setHashStep();

//          if (cartContext.haveCart()) {
//            setLoading(false);
//          }
//          else {
            postData("/api/cart/data", removeNulls({
                cart_id: cartContext.cart.id || 0,
                cuid: userContext.profile ? userContext.profile.id : null,
                // email: urlParams.email || null
            }),
                function(response) {
                    cartContext.saveCart(response);
                },
                function(error, status) {
                    if (status == 404) 
                        cartContext.deleteCart();
                    else
                        setError("An error occured loading your shopping cart.");
                },
                function() {
                    setLoading(false);
                }
            );
//          }

          window.addEventListener("hashchange", () => {
            setHashStep();
          });

          sendConversion("AW-946697273/sDSfCILHgpkBELnotcMD");
      }, 
      []
    );
  
    function setCart() {

    }
    
    function onStep(stepName) {
        const stepIndex = steps.findIndex(s => s.name == stepName);
        const nextStep = steps[stepIndex + 1].name;

        navigate("/checkout#" + nextStep);
        setStep(nextStep);

        window.scrollTo(0, 0);
        //setCompleted(produce(draft => {
        //    draft[stepName] = true;
        //}));
    }

    const stepIndex = steps.findIndex(s => s.name == step);

    const haveCoupon = cartContext.cart.coupon_code;
    const partialPayment = haveCoupon && cartContext.cart.coupon_type == "Partial Payment";

    return (
      <>
        <SEO page="checkout" title="Checkout" />
        <Page>
            <PageHeader 
                title="Checkout" 
            />

            {loading ? 
                <Loading />
            : (cartContext.haveCart()) ? 
                <Steps>
                    <Step>
                        <StepHeader onClick={() => setStep("cart")} className={step == "cart" ? "active":""}>
                            <h3>
                                <FontAwesomeSvgIcon icon={faShoppingCart} fixedWidth /> Shopping Cart
                            </h3>
                            {step != "cart" && 
                                <div>
                                    <div>
                                        <h4 className={partialPayment?"":"cost"}>{formatPrice(cartContext.cart.total)}</h4>
                                        {partialPayment && 
                                            <h4 className="balance">
                                                Balance Due: 
                                                <span>{formatPrice(cartContext.cart.total - parseFloat(cartContext.cart.coupon_amount))}</span>
                                            </h4>
                                        }
                                    </div>
                                    {!isMobile &&
                                        <div>
                                            <button>Edit</button>
                                        </div>
                                    }
                                </div>
                            }
                        </StepHeader>
                        {step == "cart" &&
                            <div>
                                <Prompt>
                                    Does your shopping cart look correct?
                                </Prompt>
                                <CartReview 
                                    cart={cartContext.cart}
                                    onError={(error) => setError(error)}
                                    onCartChange={(newCart) => setCart(newCart) }
                                    onContinue={() => onStep("cart")}
                                    continueToShop={urlParams && urlParams.returnto}
                                    onContinueShop={() => {
                                        if (urlParams && urlParams.returnto == "mysigns")
                                            navigate("/account/signs")
                                        else if (urlParams && urlParams.returnto == "myprojects")
                                            navigate("/account/projects")
                                        else if (urlParams && urlParams.returnto == "templates")
                                            navigate("/templates")
                                        else
                                            navigate("/")
                                    }}
                                />
                            </div>
                        }
                    </Step>
                    {(stepIndex > 0 || completed.contact) && 
                        <Step>
                            <StepHeader onClick={() => setStep("contact")} className={step == "contact" ? "active":""}>
                                <h3>
                                    <FontAwesomeSvgIcon icon={faUser} fixedWidth /> Contact
                                </h3>
                                {step != "contact" && 
                                    <div>
                                        <div>
                                            <h4>{cartContext.cart.user_name}</h4>
                                            <small>{cartContext.cart.user_email}</small>
                                        </div>
                                        {!isMobile &&
                                            <div>
                                                <button>Edit</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </StepHeader>
                            {step == "contact" &&
                                <div>
                                    <Prompt>
                                        How can we get in contact with you?
                                    </Prompt>
                                    <ContactData 
                                        cart={cartContext.cart}
                                        onError={(error) => setError(error)}
                                        onCartChange={(newCart) => setCart(newCart)}
                                        onBack={() => onStep("cart")}
                                        onContinue={() => onStep("contact")}
                                    />
                                </div>
                            }
                        </Step>
                    }
                    {(stepIndex > 1 || completed.shipping) && 
                        <Step>
                            <StepHeader onClick={() => setStep("shipping")} className={step == "shipping" ? "active":""}>
                                <h3>
                                    <FontAwesomeSvgIcon icon={faTruck} fixedWidth /> Shipping
                                </h3>
                                {step != "shipping" && 
                                    <div>
                                        <div>
                                            <small>{cartContext.cart.ship_address1}</small>
                                            <h4>{cartContext.cart.ship_city} {cartContext.cart.ship_state}</h4>
                                        </div>
                                        {!isMobile &&
                                            <div>
                                                <button>Edit</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </StepHeader>
                            {step == "shipping" &&
                                <div>
                                    <Prompt>
                                        Where is your order being shipped?
                                    </Prompt>
                                    <ShippingData 
                                        cart={cartContext.cart}
                                        onError={(error) => setError(error)}
                                        onCartChange={(newCart) => setCart(newCart)}
                                        onBack={() => onStep("contact")}
                                        onCart={() => setStep("cart")}
                                        onContinue={() => onStep("shipping")}
                                    />
                                </div>
                            }
                        </Step>
                    }
                    {(stepIndex > 2 || completed.billing) && 
                        <Step>
                            <StepHeader onClick={() => setStep("billing")} className={step == "billing" ? "active":""}>
                                <h3>
                                    <FontAwesomeSvgIcon icon={faMoneyBill} fixedWidth /> Billing
                                </h3>
                                {step != "billing" && 
                                    <div>
                                        <div>
                                            <small>{cartContext.cart.billing_address1}</small>
                                            <h4>{cartContext.cart.billing_city} {cartContext.cart.billing_state}</h4>
                                        </div>
                                        {!isMobile &&
                                            <div>
                                                <button>Edit</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </StepHeader>
                            {step == "billing" &&
                                <div>
                                    <Prompt>
                                        Who should the order be billed to?
                                    </Prompt>                
                                    <BillingData
                                        cart={cartContext.cart}
                                        onError={(error) => setError(error)}
                                        onCartChange={(newCart) => setCart(newCart)}
                                        onBack={() => onStep("shipping")}
                                        onContinue={() => onStep("billing")}
                                    />
                                </div>
                            }
                        </Step>
                    }
                    {(stepIndex > 3) && 
                        <Step>
                            <StepHeader onClick={() => onStep("payment")} className={step == "payment" ? "active":""}>
                                <h3>
                                    <FontAwesomeSvgIcon icon={faCreditCard} fixedWidth /> Payment
                                </h3>
                            </StepHeader>
                            {step == "payment" &&
                                <div>
                                    <Prompt>
                                        Review and complete your order.
                                    </Prompt>
                                    <PaymentData 
                                        cart={cartContext.cart}
                                        onError={(error) => setError(error)}
                                        onBack={() => onStep("billing")}
                                        onSuccess={(order) => {
                                            sendConversion("AW-946697273/h0WPCOGVi5kBELnotcMD");
                                            navigate("/ordercompleted?uid=" + order.uid);
                                        }}
                                    />
                                </div>
                            }
                        </Step>
                    }
                </Steps>
            : 
                <CartEmpty 
                    onError={(error) => setError(error)}
                    onCartChange={(newCart) => setCart(newCart)}
                />
            }

            {(error && error.length>0) && 
                <MajorError 
                    error={error}
                    log={error} 
                    onClose={() => setError("")}
                />
            }
        </Page>
    </>
    )
}

export default CheckoutPage