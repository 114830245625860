import React from "react"
import styled from "styled-components"

import Field from "./Field"

const TextArea = styled.textarea`
    flex: 1; 
    max-width: ${props => props.wide ? "100%":"200px"}; 
    width: ${props => props.wide ? "100%":"auto"}; 
    font-size: ${props => props.large ? "24px":"18px"};
    padding: 7px;
    border: 0px;
`

const MultiTextField = React.forwardRef((props, ref) => {
    return (
        <Field {...props}>
            <TextArea 
                id={props.id}
                type={props.type || "text"}
                autoComplete={props.autoComplete}
                ref={ref}
                wide={props.wide}
                value={props.value}
                name={props.name}
                placeholder={props.placeholder}
                rows={props.rows}
                style={props.style}
                onChange={props.onChange}
            />
        </Field>
    );
})

export default MultiTextField