import React from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faChevronLeft, faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { isMobile } from "react-device-detect";

import { colors } from "../../settings"

const Buttons = styled.div`
    padding: ${isMobile?15:30}px 0px;
    display: flex;

    > div {
        flex: 1;

        &.next {
            text-align: left;

            button {
                background-color: ${colors.green};
                color: white;
                white-space: nowrap;

                svg {
                    margin-left: 5px;
                }
            }
        }
        &.return {
            margin-right: 5px;

            button {
                display: flex;
                align-items: center;
                padding: 12px 20px;
                font-size: 20px;

                svg {
                    margin-right: 10px;
                }
            }
        }

        button {
            background-color: white;
            color: #666;
            border: 1px solid #666;
            padding: ${isMobile?"10px 20px":"20px 30px"};
            font-size: ${isMobile?18:25}px;
            font-weight: 300;
            border-radius: 5px;
        }
    }
`

const CheckoutButtons = (props) => {  
    return (
      <Buttons>
        {(false && props.onBack) && 
            <div className="back">
                <button onClick={props.onBack}>
                    Back
                </button>
            </div>
        }
        {props.onContinueShop && 
            <div className="return">
                <button onClick={props.onContinueShop}>
                    <FontAwesomeSvgIcon icon={faChevronLeft} />{' '}
                    <div>
                        Continue<br />
                        Shopping
                    </div>
                </button>
            </div>
        }
        {props.onNext && 
            <div className="next">
                <button disabled={props.busy} onClick={props.onNext}>
                    Continue 
                    <FontAwesomeSvgIcon icon={props.busy ? faSpinner : faChevronRight} spin={props.busy} />
                </button>
            </div>
        }
      </Buttons>
    )
}

export default CheckoutButtons