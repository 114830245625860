import React, { useState, useEffect, useContext } from "react";
import { Link } from "gatsby"
import styled from "styled-components"
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import TextField from '../fields/TextField';
import Button from "../buttons/Button"
import ErrorBox from "../boxes/ErrorBox"

import { UserContext} from "../../state/UserContext"
import { postData } from "../../services/server"
import { CartContext } from '../../state/CartContext';
import { resetSessionId } from "../../services/session";

const Form = styled.div`
    background-color: white;
    padding: ${props => props.nopadding ? 0:10}px;
    border-radius: 5px;

    > button {
        flex-shrink: 0;
        flex-grow: 0;
        width: 60px;
        min-width: 60px;
        max-width: 60px;
    }
`

function FormEmailLookup(props) {
    const userContext = useContext(UserContext);
    const cartContext = useContext(CartContext);

    const [email, setEmail] = useState(props.email || "");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [busy, setBusy] = useState(false);
    const [login, setLogin] = useState(props.login && email.length > 0 ? true:false);

    function onSearch(e, authenticated=false) {
        if (props.onSearching) {
            props.onSearching();
        }
        
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setBusy(true);
        setError("");

        resetSessionId();

        postData("/api/users/lookup/email", {
            email: email,
            secure: props.password ? 1:0
        },
        function(result) {
          //if (!authenticated) {
            userContext.rememberEmail(email);
          //}
          if (result.cart)
            cartContext.saveCart(result.cart);
                    
          
          props.onResult(result);
        },
        function(error, status) {
            if (status == 404)
                props.onResult(null);
            else if (status == 401 || status == 402)
                setLogin(true);
            else 
                setError("An error occured while looking up your data.");
        },
        function() {
            setBusy(false);
        }
      );
    }

    function onLogin(e) {
        e.preventDefault();
        e.stopPropagation();

        setBusy(true);
        setError("");

        postData("/api/users/authenticate", { 
            email: email,
            password: password
          },
          (response) => {
              if (response.message === "") {
                if (response.cart)
                    cartContext.saveCart(response.cart);
                    
                userContext.login(response.user);

                //props.onResult(response);
                onSearch(null, true);
              }
              else {
                setError(response.message);
              }
          },
          (error, status) => {
              if (status && status == 401)
                setError("Invalid email/password.")
              else
                setError("Error processing request.")
          },
          () => {
            setBusy(false);
          }
        );        
    }

    useEffect(
        () => {             
            if (props.email && props.email.length)
                onSearch();
        }, 
        [props.email]
    );

    return (
        <form>
            <Form nopadding={props.nopadding}>
                <TextField
                    id="email_lookup"
                    name="email"
                    type="email"
                    label="Email Address"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value) 
                    }}
                    onKeyDown={(e) => {
                        if (e.key == "Enter")
                            onSearch(e);
                    }}
                    disabled={busy}
                    wide
                    autoComplete="email"
                >
                    <Button 
                        icon={faSearch}
                        color="blue"
                        busy={busy}
                        disabled={email.length === 0}
                        onClick={onSearch}
                    />
                </TextField>
            </Form>

            { login && 
                <>

                <div style={{margin: "10px"}}>
                    This email address is password protected.  Please enter the password below 
                    to login and continue.
                </div>

                <Form nopadding={props.nopadding}>
                    <TextField
                        id="email_lookup_password"
                        name="password"
                        type="password"
                        label="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        onEnter={onLogin}
                        disabled={busy}
                        wide
                    >
                        <Button 
                            color="blue"
                            label="Login"
                            busy={busy}
                            disabled={email.length === 0 || password.length === 0}
                            onClick={onLogin}
                        />                            
                    </TextField>
                </Form>
                 <small style={{margin: "10px"}}>
                    <Link to="/account/forgot-password/">
                        Forgot Password?
                    </Link>
                </small>
                </>
            }

            { error.length > 0 &&
                <ErrorBox style={{margin: "10px"}}>
                    {error}
                </ErrorBox>
            }
        </form>
    )
}

export default FormEmailLookup