import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faPlus, faPhone } from '@fortawesome/free-solid-svg-icons'

import PaymentForm from "./PaymentForm";

import MultiTextField from '../fields/MultiTextField';
import CheckoutButtons from "./Buttons"
import Coupon from "../Coupon"
import { postData } from "../../services/server"
import { CartContext } from '../../state/CartContext';
import { formatPrice } from "../../utilities/utils"
import { colors, phones } from "../../settings"
import Button from "../buttons/Button";

const Totals = styled.div`
  margin-bottom: 20px;

  > div {
    margin-bottom: 8px;
    display: flex;
    font-size: 20px;
    font-weight: 300;

    label {
      width: 125px;
    }

    &.free {
      span {
        color: red;
      }
    }
    &.discount {
      span {
        color: red;
      }
    }
    &.total {
      span {
        color: ${colors.green};
        font-weight: bold;
      }
    }
  }
`
const AltPaymentForm = styled.div`
  background-color: #eee;
  justify-content: center;
  padding: 25px;

  button {
    display: block;
    padding: 20px;
    margin: 0px auto;
  }
`

const PaymentData = (props) => {
    const cartContext = useContext(CartContext);

    const [busy, setBusy] = useState(false);
    const [data, setData] = useState({
          notes: props.cart.notes || ""
        })
    //const [opaqueData, setOpaqueData] = useState(null);

    function onFieldChange(e) {
      setData({...data, [e.target.name]: e.target.value});
    }

    function onError(response) {
        const error = response.messages.message.map(err => err.text);

        props.onError(error);

        postData("/api/cart/problem", {
            cart_id: props.cart.id,
            action: "checkout",
            problem: error
        });  
    }

    function onSuccess(response, cardData) {
        //setOpaqueData(response.opaqueData);

        setBusy(true);

        let last4cc = "";

        if (cardData) {
          last4cc = cardData.cardNumber.substring(cardData.cardNumber.length-4, cardData.cardNumber.length)
        }
        
        postData("/api/cart/order", {
                ...response.opaqueData,
                last4cc,
                notes: data.notes || null,
                cart_id: props.cart.id
            }, 
            (response) => {
                cartContext.deleteCart();
                props.onSuccess(response);
            }, 
            (error, status, response) => {
                const problem = response && response.data && response.data.error ? response.data.error : "An error occurred processing your payment";

                props.onError(problem);

                postData("/api/cart/problem", {
                  cart_id: props.cart.id,
                  action: "checkout",
                  problem: problem
              }); 
            },
            () => {
              setBusy(false)
            }
        );  
    }
  
    return (
      <>
      {false && 
        <Totals>
          <div>
            <label>Cart Total:</label>
            <span>{formatPrice(props.cart.cost)}</span>
          </div>
          <div className={!props.cart.shipping ? "free":""}>
            <label>Shipping:</label>
            <span>{!props.cart.shipping ? "FREE":formatPrice(props.cart.shipping)}</span>
          </div>
          {(parseInt(props.cart.discount) > 0) && 
            <div className="discount">
              <label>Discount:</label>
              <span>-{formatPrice(props.cart.discount)}</span>
            </div>
          }
          {(parseInt(props.cart.coupon_discount) > 0) && 
            <div className="discount">
              <label>Purchase Code:</label>
              <span>-{formatPrice(props.cart.coupon_discount)}</span>
              <Coupon code={props.cart.coupon_code} />
            </div>
          }
          <div className="total">
            <label>Total:</label>
            <span>{formatPrice(props.cart.total)}</span>
          </div>
        </Totals>
        }

        <MultiTextField 
              value={data.notes}
              wide
              variant="outlined"
              name="notes"
              label="Additional Order Information"
              onChange={onFieldChange} 
              rows={2} 
          />

        <br />
        <br />

        {((props.cart.coupon_type && props.cart.coupon_type == "Alternative Payment Method") || (props.cart.admin_coupon_type && props.cart.admin_coupon_type == "Alternative Payment Method")) ? 
          <AltPaymentForm>
            <p>
              Please call us at <FontAwesomeSvgIcon icon={faPhone} /> <a href={"tel:"+phones.sales}>{phones.sales}</a> to arrange 
              your alternative payment method.
            </p>
            <Button
              label="Submit Order"
              icon={faPlus}
              busy={busy}
              color="green"
              size='large'
              onClick={() => onSuccess({opaqueData: {}})}
            />
          </AltPaymentForm>        
        :
          <PaymentForm
            cart_id={props.cart.id}
            agreeTerms={true} 
            agreeContact={true}
            onError={onError}
            onSuccess={onSuccess}
            busy={busy}
            zip={props.cart.billing_zipcode}
            fullName={props.cart.billing_name}
          />
        }

        <CheckoutButtons
          onBack={props.onBack}
        />
      </>
    )
}

export default PaymentData