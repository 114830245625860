function gtag() {
    window.dataLayer && window.dataLayer.push(arguments)
}

export function sendConversion(code, attempt=1) {
    if (window.dataLayer) {
        gtag("event", "conversion", { send_to: [code]})        
    }
    else {
        setTimeout(() => sendConversion(code, attempt+1), 1000);
    }    
}

export function sendPageView(url, attempt=1) {
    if (window.dataLayer) {
        gtag('config', 'UA-67157516-1', {'page_path': url})        
    }
    else {
        setTimeout(() => sendPageView(code, attempt+1), 1000);
    }    

}